<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование типа лицензии"
        update
        url-update="/license_type/update/:id"
        url-back="/license_type"
        action-one="license_type/one"
    >
        <license-type-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import LicenseTypeForm from '@/components/forms/LicenseTypeForm.vue'
export default {
    name: 'LicenseTypeUpdate',
    components: { FormView, LicenseTypeForm }
}
</script>